<template>
  <div class="drawer__content">
    <nav class="drawer__nav leftright" aria-labelledby="return_shopping">
      <a href="" class="drawer__link" @click="backAction">
        <i class="fa-solid fa-arrow-left"></i>
        <span id="return_shopping">{{ backText }}</span>
      </a>
    </nav>
    <header class="drawer__header">
      <div class=" leftright">
        <h2 class="header2-24 ">Your shopping details</h2>
      </div>
    </header>
    <div class="drawer__content">
      <div class="drawer__empty" v-if="isLoading">
        <div class="drawer__spinner-large">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
      <div class="drawer__active" v-else>
        <div class="drawer__active-items">
          <CurrentAccount v-if="showAccounts"
            :changeMode="changeMode"
            :curr_address="current_addr"
            :display_obj="account_display_obj"
            :loadingAccounts="isLoading"
          />
          <CurrentAccount
            :changeMode="changeMode"
            :curr_address="current_addr"
            :display_obj="address_display_obj"
            :loadingAccounts="isLoading"
          />
        </div>
      </div>
      <div class="drawer__active-footer">
        <div class="address-filter__buttons-container">
          <button class="wc wc-button wc-button--primary" @click="closeDrawer">
            <span class="label">Continue shopping</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import axios from 'axios';
import { doAdobeEvents } from '@/helpers/adobe';
import CurrentAccount from '@/components/accountsframe/CurrentAccount.vue';

export default {
  name: 'ShoppingDetails',
  components: {
    CurrentAccount
  },
  data: function data() {
    return {
      curr_accounts: [],
      account_display_obj: {
        icon: 'fa-briefcase',
        heading: 'Account',
        mode: 'accounts',
        select_msg: 'Select account',
        unselected: 'No account selected'
      },
      address_display_obj: {
        icon: 'fa-truck',
        heading: 'Delivery address',
        mode: 'addresses',
        select_msg: 'Select address',
        unselected: 'No address selected'
      }
    };
  },
  props: {
    changeMode: {
      type: Function,
      required: true
    },
    closeDrawer: {
      type: Function,
      required: true,
    }
  },
  computed: {
    ...mapGetters('accountsframe', [
      'ccode',
      'current_addr'
    ]),
    ...mapState('accountsframe', [
      'accounts',
      'accountsStatus',
    ]),
    isRetail() {
      return window.nxDatalayer.global.channel === 'retail';
    },
    isLoading() {
      return this.accountsStatus === 'loading';
    },
    showAccounts() {
      return !this.isRetail && (this.uniqueCcodes.size > 1);
    },
    uniqueCcodes() {
      return new Set(this.accounts.map((el) => el.ccode));
    },
    backText() {
      return window.nx.addToCart.AccountsFrameBackAction
        ? 'My account'
        : 'Continue shopping';
    }
  },
  methods: {
    ...mapActions('accountsframe', [
      'getAccounts'
    ]),
    backAction(evt) {
      this.closeDrawer(evt);
      if (window.nx?.addToCart?.AccountsFrameBackAction) {
        window.nx.addToCart.AccountsFrameBackAction.action();
        window.nx.addToCart.AccountsFrameBackAction = null;
      }
    }
  },
  mounted() {
    this.getAccounts();
  },
};
</script>
